import { Api2 } from "./api";
import { AxiosRequestConfig } from "axios";

export class ContractUploadFileApi extends Api2 {
  private token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  public uploadFile(
    data: Record<string, any>,
    config: AxiosRequestConfig
  ): Promise<any> {
    return this.post<any>("getPreSignedUploadUrlContract", data, config);
  }

  public uploadFilePut(
    url: Record<string, any>,
    config: AxiosRequestConfig
  ): Promise<any> {
    return this.put<any>(`${url}`, {}, config);
  }
}
