






















































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import DatetimePicker from "vue-persian-datetime-picker";
import Office from "../../components/inputs/office.vue";
import UserAutoComplete from "@/views/components/inputs/user.vue";
import EventAutoComplete from "@/views/components/inputs/event.vue";
import { EventApi } from "@/networks/EventApi";
import Enum from "@/config/enum";
import Country from "@/views/components/inputs/country.vue";
import { ContractApi } from "@/networks/ContractApi";
import { ContractUploadFileApi } from "@/networks/ContractUploadFileApi";
import FileUploader from "@/views/components/inputs/fileUploader.vue";
import DatePicker from "vue-persian-datetime-picker";
import moment from "moment-jalaali";

@Component({
  components: {
    FileUploader,
    DatetimePicker,
    office: Office,
    UserAuto: UserAutoComplete,
    EventAuto: EventAutoComplete,
    country: Country,
    DatePicker
  }
})
export default class Contract extends Vue {
  private types = Enum.MigrationMethods;
  private paymentTypes = Enum.TypePayment;
  private typeCurrency = Enum.currencies;
  private paymentNames = "پیش پرداخت قرارداد";
  private staff = [];
  private file: any = [];
  private payment: any = {
    name: "پیش پرداخت قرارداد"
  };
  private form: Record<string, any> = {};
  private errors: Record<string, Array<string>> = {};
  private loading = true;
  private filesId: any = [];
  private progress: any = {};
  private fileuploaded: any = {};

  mounted() {
    // this.getEvent(this.$route.params.id);
  }

  public async setChooseFile(
    e: any,
    index: number | string,
    id: number | string
  ): Promise<void> {
    console.log(e, index);
    e.target.previousSibling.innerText = e.target.files[0].name;
    this.file[index].file = e.target.files[0];
    const file = e.target.files[0];
    let user = JSON.parse(localStorage.getItem("user") || "{}");
    const data = {
      name: file.name,
      size: file.size,
      type: "contract",
      mime: file.type,
      user_id: user.id
    };
    try {
      const res: any = await new ContractUploadFileApi().uploadFile(data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        onUploadProgress: (e) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const progress = parseInt(Math.round((e.loaded / e.total) * 100));
          let file = this.file.map((item: any) =>
            item.id === id ? (item = { id: id, progress: progress }) : item
          );
          this.file = file;
          this.$forceUpdate();
        }
      });
      console.log(res, data); // res.data.data.url  res.data.data.file.id
      let fileuploaded = this.fileuploaded;
      fileuploaded[id] = res.data.data.file.id;
      this.fileuploaded = fileuploaded;
      console.log(this.fileuploaded);
      try {
        const resp: any = await new ContractUploadFileApi().uploadFilePut(
          res.data.data.url,
          {
            onUploadProgress: (e) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const progress = parseInt(Math.round((e.loaded / e.total) * 100));
              let file = this.file.map((item: any) =>
                item.id === id ? (item = { id: id, progress: progress }) : item
              );
              this.file = file;
              this.$forceUpdate();
            }
          }
        );
        console.log(resp);
        store.commit("showError", {
          message: "فایل  موردنظر با موفقیت آپلود شد",
          color: "success"
        });
      } catch (err: any) {
        console.log(err);
        store.commit("showError", {
          message: "خطای نامشخص",
          color: "danger"
        });
      }
    } catch (err: any) {
      console.log(err);
      store.commit("showError", {
        message: err.response.data.errors.size[0] || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public addTeam(): void {
    const mentors = this.file;
    mentors.push({ id: this.file.length });
    this.file = mentors;
  }

  public setChoosCountries(value: any) {
    console.log(value);
    this.form.countries = value;
  }

  public deleteTeam(index: number, id: number | string): void {
    const mentors = [...this.file];
    mentors.splice(index, 1);
    this.file = mentors;
    let fileuploaded = this.fileuploaded;
    delete fileuploaded[id];
    this.fileuploaded = fileuploaded;
    let file = this.file.map((item: any) =>
      item.id === id ? (item = { id: id }) : item
    );
    this.file = file;
  }

  public async submit(): Promise<void> {
    try {
      let form: any = { ...this.form, eventId: this.$route.params.id };
      form = this.getFormData(form);
      Object.keys(this.fileuploaded).map((item, index) =>
        form.append(`file[${index}]`, this.fileuploaded[item])
      );
      form.append(`price`, this.form.price);
      form.append(`payment[0][name]`, this.payment.name);
      form.append(`payment[0][price]`, this.payment.price);
      form.append(
        `payment[0][type_of_currency]`,
        this.payment.type_of_currency
      );
      form.append(
        `payment[0][price_of_currency]`,
        this.payment.price_of_currency
      );
      form.append(`payment[0][payment_type]`, this.payment.payment_type);
      form.append(
        `payment[0][due_date]`,
        moment(this.payment.due_date, "jYYYY/jMM/jDD").format("YYYY/MM/DD")
      );
      form.append(`payment[0][status]`, this.payment.status || 0);
      if (this.payment.file) {
        form.append(`payment[0][file]`, this.payment.file);
      }
      const res: AxiosResponse = await new ContractApi().store(form);
      console.log(res);
      if (!this.$route.params.id) {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        this.form = {};
      }
      this.errors = {};
      store.commit("showError", {
        message: res.data.message,
        color: "success"
      });
      await this.$router.push({ name: "dashboard" });
    } catch (error: any) {
      console.log("error", error);
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }

  private getFormData = (object: Record<string, any>) =>
    Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());

  public async getEvent(id: string): Promise<void> {
    try {
      const res: AxiosResponse = await new EventApi().view(Number(id));
      const item = {
        ...res.data.data
      };
      item.type = item.type_original;
      this.form = item;
      this.loading = false;
    } catch (error: any) {
      if (error.response.status == 422) {
        this.errors = error.response.data.errors;
      } else {
        store.commit("showError", {
          message: error.response.data.message || "خطای نامشخص",
          color: "danger"
        });
      }
    }
  }
}
